import { FC, useEffect, useRef, useState } from "react";
import { useAppTheme } from "../../utils/theme";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SearchOff } from "@mui/icons-material";
import { Button, Card, CardContent, Chip, IconButton } from "@mui/material";
import { useUserStore } from "../../stores/user.store";
import {
	useDownloadBeneficiaryReport,
	useGetBeneficiaryReport,
} from "../../hooks/useReports";
import { useCurrencySetting } from "../../hooks/useSetting";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import { headCellsConfig } from "../../utils/tableConfig";
import { useXlsGenerate } from "../../providers/XlsGeneratorProvider/XlsGeneratorContext";
import { usePDFGenerate } from "../../providers/PDFGeneratorProvider/PDFGeneratorContext";
import { toTitleCase } from "../../utils/toTitleCase";
import DataTable, {
	DataTableCell,
	DataTableCellValue,
	HeadCell,
} from "../../components/DataTable";
import ReportFilters, { ReportFiltersProps } from "./ReportFilters";
import { buttonExport, runReportButton, searchOffIcon } from "./Report.styles";
import { useQueryClient } from "@tanstack/react-query";
import { useSettings } from "../../hooks/useSetting";
import { formatCommaSeparatedNumber } from "../../utils/general";

interface ExtendedReportFiltersProps extends ReportFiltersProps {
	pageSize: number;
	pageNumber: number;
}

const BeneficiaryReport: FC = () => {
	const theme = useAppTheme();
	const queryClient = useQueryClient();
	const { userInfo } = useUserStore();
	const currencySetting = useCurrencySetting();
	const createAuditlogMutation = useCreateAuditlog();

	const userProgramsIds =
		userInfo?.programs.map((program) => program.programId) || [];

	const programsDefaultValue = userProgramsIds.sort().join(",") || "";

	const [filters, setFilters] = useState<ReportFiltersProps>({
		programId: [programsDefaultValue],
		providerSiteId: [],
		providerId: "",
		providerNpi: "",
		beneficiary: "",
		beneficiaryCin: "",
	});

	const [searchReport, setSearchReport] = useState(false);

	const [reportFilters, setReportFilters] =
		useState<ExtendedReportFiltersProps>({
			programId: [programsDefaultValue],
			providerSiteId: [],
			providerId: "",
			providerNpi: "",
			beneficiary: "",
			beneficiaryCin: "",
			pageSize: 10,
			pageNumber: 0,
		});

	const {
		data: beneficiaryReport,
		isLoading,
		isRefetching,
		refetch: refetchBeneficiaryList,
	} = useGetBeneficiaryReport(reportFilters, searchReport);

	const { refetch: downloadReportRefetch, isRefetching: isDownloadRefetching } =
		useDownloadBeneficiaryReport({ ...reportFilters, isDownload: "true" });

	const { exportToExcel } = useXlsGenerate();
	const { generateTablePDF } = usePDFGenerate();

	const postAuditlog = async ({
		action,
		actionDetail,
		actionCode,
	}: {
		action: string;
		actionDetail: string;
		actionCode: string;
	}) => {
		await createAuditlogMutation.mutateAsync({
			appType: "WEB_BACK_OFFICE",
			module: "Reporting",
			option: "Beneficiary Report",
			actionCode,
			action,
			detail: actionDetail,
			createdBy: userInfo?.id || "",
		});
	};

	const postAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (!postAuditlogCalledRef.current) {
			postAuditlog({
				action: "When entering",
				actionDetail: "Access to beneficiary report",
				actionCode: "WEB_REPORT_BENEF_ACCESS",
			});
			postAuditlogCalledRef.current = true;
		}
	}, [postAuditlogCalledRef]);

	useEffect(() => {
		if (searchReport) {
			refetchBeneficiaryList();
			setSearchReport(false);
		}
	}, [searchReport]);

	useEffect(() => {
		return () => {
			queryClient.removeQueries({
				queryKey: ["sims-report-beneficiary"],
			});
		};
	}, [queryClient]);

	const handleExportExcel = () => {
		downloadReportRefetch().then(({ data }) => {
			const reportData = data?.data || [];
			const headersMap = headCellsConfig.beneficiaryReportListView.reduce(
				(acc, header) => ({ ...acc, [header.label]: header.id }),
				{},
			);
			exportToExcel(headersMap, reportData, "beneficiary_report");
		});
	};

	const handleExportPdf = () => {
		downloadReportRefetch().then(({ data }) => {
			const reportData = data?.data || [];
			const headers = headCellsConfig.beneficiaryReportListView.map(
				(header) => header.label,
			);
			const pdfData = reportData.map((item) => [
				item.beneficiary || "-",
				item.beneficiaryCIN || "-",
				item.providerSite || "-",
				item.provider || "-",
				item.providerNPI || "-",
				item.enrollmentPeriod || "-",
				item.status || "-",
				item.totalNeg || 0,
				item.totalPos || 0,
				item.totalAbs || 0,
				item.incentiveEarned || 0,
				item.incentiveReceived || 0,
				item.bankBalance || 0,
			]);
			generateTablePDF(headers, pdfData, "beneficiary_report");
		});
	};

	const onChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setReportFilters((prevFilters) => ({
			...prevFilters,
			pageNumber: newPage,
		}));
		setSearchReport(true);
	};

	const onChangeSize = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newPageSize = Number(event.target.value);
		setReportFilters((prevFilters) => ({
			...prevFilters,
			pageNumber: 0,
			pageSize: newPageSize,
		}));
		setSearchReport(true);
	};

	const handleRunReport = () => {
		if (reportFilters.programId.length === 0) return;
		setSearchReport(true);
		setReportFilters({ ...filters, pageNumber: 0, pageSize: 10 });
		postAuditlog({
			action: "When searching",
			actionDetail: "Beneficiary report search",
			actionCode: "WEB_REPORT_BENEF_SEARCH",
		});
	};

	const handleClearFilters = () => {
		setFilters({
			programId: [programsDefaultValue],
			providerSiteId: [],
			providerId: "",
			providerNpi: "",
			beneficiary: "",
			beneficiaryCin: "",
		});
		setReportFilters({
			programId: [programsDefaultValue],
			providerSiteId: [],
			providerId: "",
			providerNpi: "",
			beneficiary: "",
			beneficiaryCin: "",
			pageSize: 10,
			pageNumber: 0,
		});
		setSearchReport(false);
		queryClient.removeQueries({
			queryKey: ["sims-report-beneficiary"],
		});
	};

	const onFiltersChange = (newFilters: ReportFiltersProps) => {
		setFilters(newFilters);
	};
	const { displayBeneficiaryName } = useSettings();

	function createData(
		id: number,
		beneficiary: DataTableCellValue,
		beneficiaryCIN: DataTableCellValue,
		providerSite: DataTableCellValue,
		provider: DataTableCellValue,
		providerNPI: DataTableCellValue,
		enrollmentPeriod: DataTableCellValue,
		status: DataTableCellValue,
		totalNeg: DataTableCellValue,
		totalPos: DataTableCellValue,
		totalAbs: DataTableCellValue,
		incentiveEarned: DataTableCellValue,
		incentiveReceived: DataTableCellValue,
		bankBalance: DataTableCellValue,
	): DataTableCell {
		return {
			id,
			beneficiary,
			beneficiaryCIN,
			providerSite,
			provider,
			providerNPI,
			enrollmentPeriod,
			status,
			totalNeg,
			totalPos,
			totalAbs,
			incentiveEarned,
			incentiveReceived,
			bankBalance,
		};
	}

	const rows =
		beneficiaryReport?.data?.map((item, index) => {
			const beneficiary = item.beneficiary || "-";
			const cin = item.beneficiaryCIN || "-";
			const site = item.providerSite || "-";
			const provider = item.provider || "-";
			const providerNpi = item.providerNPI || "-";
			const enrollmentPeriod = item.enrollmentPeriod || "-";
			const status = item.status || "-";
			const totalNeg = item.totalNeg || 0;
			const totalPos = item.totalPos || 0;
			const totalAbs = item.totalAbs || 0;
			const incentiveEarned = item.incentiveEarned || 0;
			const incentiveReceived = item.incentiveReceived || 0;
			const bankBalance = item.bankBalance || 0;

			const row = createData(
				index,
				{
					id: beneficiary,
					node: (
						<div className="w-20 text-wrap overflow-hidden overflow-ellipsis">
							{beneficiary}
						</div>
					),
				},
				{
					id: cin,
					node: (
						<div className="w-20 text-nowrap overflow-hidden overflow-ellipsis">
							{cin}
						</div>
					),
				},
				{
					id: site,
					node: (
						<div className="w-20 text-wrap overflow-hidden overflow-ellipsis">
							{site}
						</div>
					),
				},
				{
					id: provider,
					node: (
						<div className="w-20 text-wrap overflow-hidden overflow-ellipsis">
							{provider}
						</div>
					),
				},
				{
					id: providerNpi,
					node: (
						<div className="w-20 text-wrap overflow-hidden overflow-ellipsis">
							{providerNpi}
						</div>
					),
				},
				{
					id: enrollmentPeriod,
					node: (
						<div className="w-20 text-wrap overflow-hidden overflow-ellipsis">
							{enrollmentPeriod}
						</div>
					),
				},
				{
					id: status,
					node: (
						<Chip
							sx={{
								bgcolor:
									status === "ACTIVE"
										? theme.palette.secondary.light
										: theme.palette.outline.dark,
							}}
							label={<div className="text-xs">{toTitleCase(status)}</div>}
						/>
					),
				},
				{
					id: totalNeg,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{totalNeg}
						</div>
					),
				},
				{
					id: totalPos,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{totalPos}
						</div>
					),
				},
				{
					id: totalAbs,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{totalAbs}
						</div>
					),
				},
				{
					id: incentiveEarned,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{`${currencySetting.mask}${formatCommaSeparatedNumber(incentiveEarned)}`}
						</div>
					),
				},
				{
					id: incentiveReceived,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{`${currencySetting.mask}${formatCommaSeparatedNumber(incentiveReceived)}`}
						</div>
					),
				},
				{
					id: bankBalance,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{`${currencySetting.mask}${formatCommaSeparatedNumber(bankBalance)}`}
						</div>
					),
				},
			);

			return row;
		}) || [];

	let headCells: HeadCell[] = headCellsConfig.beneficiaryReportListView;
	let adjustedRows = rows;
	if (displayBeneficiaryName && !displayBeneficiaryName.default) {
		headCells = headCells.slice(1);
		adjustedRows = rows.map((row) => {
			const newRow = { ...row };
			delete newRow.beneficiary;
			return newRow;
		});
	}

	return (
		<>
			<div className="w-full h-full overflow-y-auto scroll-smooth">
				<div className="container">
					<div className="flex flex-col items-center justify-start min-h-screen space-y-4">
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								width: "100%",
							}}
						>
							<Typography variant="headlineSmall">
								Beneficiary report <InfoOutlinedIcon />
							</Typography>
						</Box>

						<Card sx={{ minWidth: 275, width: "100%" }}>
							<CardContent className="space-y-5">
								<Box
									sx={{
										display: "flex",
										gap: "1rem",
									}}
								>
									<Button
										size="large"
										variant="outlined"
										sx={buttonExport(theme)}
										onClick={handleExportExcel}
										disabled={
											isLoading ||
											isRefetching ||
											isDownloadRefetching ||
											!beneficiaryReport?.total
										}
									>
										DOWNLOAD EXCEL
									</Button>
									<Button
										size="large"
										variant="outlined"
										sx={buttonExport(theme)}
										onClick={handleExportPdf}
										disabled={
											isLoading ||
											isRefetching ||
											isDownloadRefetching ||
											!beneficiaryReport?.total
										}
									>
										DOWNLOAD PDF
									</Button>
								</Box>
								<Box
									sx={{
										display: "grid",
										gridTemplateColumns: {
											xs: "repeat(1, 1fr)",
											sm: "repeat(2, 1fr)",
											md: "repeat(4, 1fr)",
											lg: "repeat(5, 1fr)",
										},
										gap: "1rem",
										width: "100%",
									}}
								>
									<ReportFilters
										onFiltersChange={onFiltersChange}
										filters={filters}
									/>
									<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
										<Button
											size="large"
											onClick={handleRunReport}
											color="primary"
											variant="contained"
											sx={runReportButton(theme)}
										>
											RUN REPORT
										</Button>

										<IconButton
											size="large"
											aria-label="clear filters"
											sx={searchOffIcon(theme)}
											onClick={handleClearFilters}
										>
											<SearchOff className="text-icon-primary" />
										</IconButton>
									</Box>
								</Box>

								<DataTable
									data={adjustedRows}
									rowsPerPage={reportFilters.pageSize}
									order="asc"
									orderBy="none"
									page={reportFilters.pageNumber}
									headCells={headCells}
									total={beneficiaryReport?.total}
									isLoading={isRefetching || isLoading}
									onChangePage={onChangePage}
									onChangeSize={onChangeSize}
								/>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		</>
	);
};

export default BeneficiaryReport;
