import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Typography,
} from "@mui/material";
import { FC } from "react";

export type OptionHelpProps = {
	id: string;
	url?: string;
	title: string;
	description: string;
	image: string;
	buttonText: string;
	colorButton:
		| "inherit"
		| "primary"
		| "secondary"
		| "success"
		| "error"
		| "info"
		| "warning"
		| undefined;
	buttonVariant: "text" | "outlined" | "contained" | undefined;
};
const OptionHelp: FC<OptionHelpProps> = (props: OptionHelpProps) => {
	const {
		id,
		title,
		description,
		image,
		buttonText,
		colorButton,
		buttonVariant,
		url,
	} = props;

	const onClickHandlerButtonOption = () => {
		window.open(url, "_blank");
	};

	return (
		<Grid item sm={6} md={4} key={id}>
			<Card sx={{ minHeight: "29rem" }}>
				<CardContent>
					<Box
						sx={{ height: "25rem" }}
						display="flex"
						flexDirection="column"
						gap="1rem"
						flexWrap="wrap"
					>
						<CardMedia
							sx={{ maxWidth: "100%", maxHeight: "210px" }}
							component="img"
							image={image}
							alt="green iguana"
						/>
						<div style={{ textAlign: "left" }}>
							<Typography variant="titleSmallOptionHelp">{title}</Typography>
						</div>
						<Typography variant="bodyOptionHelp" color="text.secondary">
							{description}
						</Typography>
					</Box>
				</CardContent>
				<CardActions sx={{ padding: "15px", minHeight: "6.5rem" }}>
					<Button
						size="large"
						sx={{ fontWeight: "600" }}
						color={colorButton}
						variant={buttonVariant}
						onClick={onClickHandlerButtonOption}
					>
						{buttonText}
					</Button>
				</CardActions>
			</Card>
		</Grid>
	);
};
export default OptionHelp;
