import React, { useEffect, useMemo } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Providers from "./utils/provider";
import "./i18n/config";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "./components/Spinner";
import Layout from "./views/Layout";
import UserList from "./pages/User/List";
import AddUser from "./pages/User/AddUser";
import EditUser from "./pages/User/EditUser";
import { useUserStore } from "./stores/user.store";
import { useMenuStore } from "./stores/menu.store";
import HelpSupportList from "./pages/HelpAndSupport/List";
import Unauthorized from "./pages/Unauthorized/Index";
import AccessDenied from "./pages/AccessDenied";
import BeneficiaryReport from "./pages/Reports/BeneficiaryReport";
import CardsList from "./pages/Cards";
import { UserStatus } from "./domain/user.interface";
import Callback from "./pages/Callback";
import Dashboard from "./pages/Dashboard";
import ProviderReport from "./pages/Reports/ProviderReport";
import { setToken } from "./utils/axios.create";

const BeneficiaryList = React.lazy(() => import("./pages/Beneficiary/List"));
const BeneficiaryProfile = React.lazy(
	() => import("./pages/Beneficiary/Profile"),
);

function App() {
	const {
		loginWithRedirect,
		isAuthenticated,
		isLoading: isAuthLoading,
		error,
		user,
		getAccessTokenSilently,
	} = useAuth0();
	const {
		menus,
		userInfo,
		getUserByEmail,
		isLoading: isUserLoading,
	} = useUserStore();
	const { setMenuItems } = useMenuStore();

	useEffect(() => {
		if (userInfo && menus) {
			setMenuItems(menus);
		}
	}, [userInfo, menus]);

	if (error) {
		return <div>Oops... {error.message}</div>;
	}

	useEffect(() => {
		if (!isAuthLoading && isAuthenticated && user?.email) {
			const getToken = async () => {
				const token = await getAccessTokenSilently();
				setToken(token);
				await getUserByEmail(user.email as string);
			};
			getToken();
		}
	}, [
		isAuthLoading,
		isAuthenticated,
		getAccessTokenSilently,
		user?.email,
		userInfo?.email,
	]);

	useEffect(() => {
		if (!isAuthenticated && !isAuthLoading) {
			loginWithRedirect();
		}
	}, [isAuthenticated, isAuthLoading]);

	const showComponent = useMemo<"spinner" | "accessDenied" | "continue">(() => {
		if (isAuthLoading || isUserLoading || !user || !userInfo) {
			return "spinner";
		} else if (userInfo && userInfo.status === UserStatus.INACTIVE) {
			return "accessDenied";
		} else {
			return "continue";
		}
	}, [isAuthLoading, isUserLoading, user, userInfo]);

	return (
		<>
			{showComponent === "spinner" ? (
				<div className="flex w-screen h-screen items-center justify-center">
					<Spinner />
				</div>
			) : (
				<Router>
					<Providers>
						{showComponent == "accessDenied" && <AccessDenied />}
						{showComponent == "continue" && (
							<Layout>
								<Routes>
									<Route path="/" element={<Dashboard />} />
									<Route path="/dashboard" element={<Dashboard />} />
									<Route path="/callback" element={<Callback />} />
									<Route path="/users" element={<UserList />} />
									<Route path="/user" element={<AddUser />} />
									<Route path="/users/:id" element={<EditUser />} />
									<Route path="/beneficiaries">
										<Route
											path="/beneficiaries"
											element={<BeneficiaryList />}
										/>
										<Route
											path="/beneficiaries/:beneficiaryId"
											element={<BeneficiaryProfile />}
										/>
									</Route>
									<Route path="/cards" element={<CardsList />} />
									<Route
										path="/beneficiaryReport"
										element={<BeneficiaryReport />}
									/>
									<Route path="/providerReport" element={<ProviderReport />} />
									<Route
										path="/help-and-support"
										element={<HelpSupportList />}
									/>
									<Route path="/unauthorized" element={<Unauthorized />} />
								</Routes>
							</Layout>
						)}
					</Providers>
				</Router>
			)}
		</>
	);
}

export default App;
