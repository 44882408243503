import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { MuiTelInput } from "mui-tel-input";
import BasicDatepicker from "../../BasicDatepicker";
import BasicSelect from "../../BasicSelect/index";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	useCreateBeneficiary,
	useGetProgramsByIds,
	useGetProvidersByProgramId,
	useGetProviderSitesByOUId,
	useReenrollBeneficiary,
} from "../../../hooks/useBeneficiaries/index";
import { useQueryClient } from "@tanstack/react-query";
import useDate from "../../../hooks/useDate";
import {
	BeneficiaryResponse,
	BeneficiaryPayload,
	Program,
} from "../../../domain/beneficiary.interface";
import { useEffect, useState } from "react";
import {
	a11yProps,
	ACCOUNT_NAME,
	generateSampleFromRegex,
	toBase64,
} from "../../../utils/general";
import { useFeedBack } from "../../../providers/FeedBackProvider/FeedBackContext";
import { ErrorResponse } from "../../../domain/error.interface";
import { useCreateAuditlog } from "../../../hooks/useAuditlog";
import { useTranslation } from "react-i18next";
import TabPanel from "../../TabPanel";
import createValidationSchema from "./validation.schema";
import { CustomDialog } from "../../styled/CustomDialog";
import { CustomStack } from "../../styled/CustomStack";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useAppTheme } from "../../../utils/theme";
import UploadButton from "../../UploadButton";
import { useUserStore } from "../../../stores/user.store";
import GenericAlertModal from "../GenericAlertModal";
import { compareFields } from "./compare.fields";
import { useSettings } from "../../../hooks/useSetting";

interface RegisterBeneficiaryModalProps {
	open: boolean;
	isRegister: boolean;
	onClose: () => void;
	beneficiary?: BeneficiaryResponse;
	onReenroll: () => void;
}

export default function RegisterBeneficiaryModal({
	open,
	isRegister,
	onClose,
	beneficiary,
	onReenroll,
}: RegisterBeneficiaryModalProps) {
	const { dayjs } = useDate();
	const { t } = useTranslation("common");
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { userInfo } = useUserStore();
	const theme = useAppTheme();
	const { showSnackBar: showError } = useFeedBack();

	const [selectedTab, setSelectedTab] = useState(0);
	const [openGenericDialog, setOpenGenericDialog] = useState(false);
	const createBeneficiary = useCreateBeneficiary();
	const reenrollBeneficiary = useReenrollBeneficiary({
		beneficiaryId: beneficiary?._id || "",
		enrollmentId: beneficiary?.lastEnrollment._id || "",
	});

	const { data: programs, isLoading: isLoadingPrograms } = useGetProgramsByIds(
		userInfo?.programs.map((program) => program.programId) || [],
	);

	const [ids, setIds] = useState({
		programId: "",
		providerId: "",
		providerSiteId: "",
	});
	const [programsOptions, setProgramsOptions] = useState<
		Array<{ key: string; value: string }>
	>([]);

	const [selectedProgram, setSelectedProgram] = useState<Program | undefined>();
	const [userProgram, setUserProgram] = useState<Program | undefined>();
	const {
		beneficiaryCINFormat,
		displayBeneficiaryAddress,
		displayBeneficiaryName,
		displayBeneficiaryCMEligible,
		minAgeValidation,
	} = useSettings();

	const {
		data: providerSites,
		isLoading: isLoadingProviderSites,
		refetch: refetchProviderSites,
	} = useGetProviderSitesByOUId({
		ouIds: userProgram?.orgUnitId || [],
		queryKey: "sims-beneficiaries-register-provider-sites",
	});

	const {
		data: providers,
		isLoading: isLoadingProviders,
		refetch: refetchProviders,
	} = useGetProvidersByProgramId(
		[selectedProgram?._id || ""],
		[ids.providerSiteId || ""],
	);

	const createAuditlogMutation = useCreateAuditlog();

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
	};

	const isConsentFormRequired = selectedProgram?.parameters.consent
		? isRegister || beneficiary?.lastEnrollment.isNewEnrollment || false
		: false;

	const schema = React.useMemo(
		() =>
			createValidationSchema(t, {
				isEligibility: selectedProgram?.parameters.eligibility || false,
				beneficiaryCINFormat: (beneficiaryCINFormat?.default as string) ?? "",
				displayBeneficiaryAddress:
					displayBeneficiaryAddress?.default as boolean,
				displayBeneficiaryCMEligible:
					displayBeneficiaryCMEligible?.default as boolean,
				displayBeneficiaryName: displayBeneficiaryName?.default as boolean,
				isNewReenroll: isConsentFormRequired,
				minAgeValidation: minAgeValidation?.default as number,
			}),
		[
			beneficiaryCINFormat,
			displayBeneficiaryAddress,
			displayBeneficiaryName,
			displayBeneficiaryCMEligible,
			isConsentFormRequired,
			t,
		],
	);

	type BeneficiaryType = yup.InferType<typeof schema>;

	const {
		control,
		handleSubmit,
		reset,
		getValues,
		setValue,
		trigger,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			firstName: beneficiary?.beneficiary.firstName || "",
			lastName: beneficiary?.beneficiary.lastName || "",
			email: beneficiary?.beneficiary.email || "",
			phoneNumber: beneficiary?.beneficiary.phone[0].code
				? `${beneficiary?.beneficiary.phone[0].code} ${beneficiary?.beneficiary.phone[0].number}`
				: "",
			dateOfBirth: beneficiary?.beneficiary.dateOfBirth,
			addressLine1: beneficiary?.beneficiary.address.addressLine1 || "",
			addressLine2: beneficiary?.beneficiary.address.addressLine2 || "",
			city: beneficiary?.beneficiary.address.city || "",
			state: beneficiary?.beneficiary.address.state || "",
			zipCode: beneficiary?.beneficiary.address.zipCode || "",
			program: beneficiary?.lastEnrollment.programId || "",
			providerSite: beneficiary?.lastEnrollment.orgUnitId || "",
			provider: beneficiary?.lastEnrollment.providerId || "",
			clientIDNumber: beneficiary?.beneficiary.sims.clientIdNumber || "",
			cmVisitSchedule: beneficiary?.beneficiary.sims.cmVisitSchedule || "",
			medicaidEligibility: beneficiary?.beneficiary.sims.medicaidEligibility
				? "true"
				: "false",
			cmEligibility: beneficiary?.beneficiary.sims.cmEligibility,
		},
	});

	const onCreateHandler = async (data: BeneficiaryPayload) => {
		const response = await createBeneficiary.mutateAsync(data);

		queryClient.invalidateQueries({
			queryKey: ["sims-beneficiaries"],
		});

		navigate(`/beneficiaries/${response.beneficiaryId}`);
	};

	const onReenrollHandler = async (data: BeneficiaryPayload) => {
		const response = await reenrollBeneficiary.mutateAsync(data);

		queryClient.invalidateQueries({
			queryKey: ["sims-beneficiaries"],
		});

		onReenroll();

		navigate(`/beneficiaries/${response.beneficiaryId}`);
	};

	const onSubmit = async (data: BeneficiaryType) => {
		const { phoneNumber: phone } = data;
		const phoneParts = phone?.split(" ") ?? [];
		const phoneCode = phoneParts?.length > 1 ? phoneParts[0] : "";
		const phoneNumber =
			phoneParts?.length > 1 ? phoneParts.slice(1).join(" ") : "";
		const cleanedPhoneNumber = phoneNumber?.replace(/\s/g, "").trim();

		// @ts-expect-error - is type file
		const consentFile = data.file ? await toBase64(data.file) : "";
		// @ts-expect-error - is type file
		const fileType = data.file ? data.file.type : "";
		// @ts-expect-error - is type file
		const fileName = data.file ? data.file.name : "";
		const rawBase64 = consentFile.split(",")[1];

		const dataMapped: BeneficiaryPayload = {
			account: ACCOUNT_NAME,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			phone: [
				{
					code: phoneCode || "",
					number: cleanedPhoneNumber || "",
				},
			],
			dateOfBirth: dayjs(data.dateOfBirth).toISOString(),
			address: {
				addressLine1: data.addressLine1,
				addressLine2: data.addressLine2,
				city: data.city,
				state: data.state,
				zipCode: data.zipCode,
			},
			sims: {
				clientIdNumber: data.clientIDNumber,
				medicaidEligibility: Boolean(data.medicaidEligibility),
				cmVisitSchedule: data.cmVisitSchedule,
				cmEligibility: data.cmEligibility,
			},
			userId: userInfo?.id as string,
			programId: data.program,
			orgUnitId: data.providerSite,
			providerId: data.provider,
			providerSite: data.providerSite,
			consentFile: rawBase64,
			fileType,
			fileName,
		};

		try {
			if (!isRegister) {
				const updatedFields = compareFields(
					dataMapped,
					beneficiary as BeneficiaryResponse,
					cleanedPhoneNumber,
				);
				const detail =
					updatedFields.length > 0
						? `Beneficiary's ${updatedFields.join(", ")} were updated by ${userInfo?.firstName} ${userInfo?.lastName}`
						: `Beneficiary were updated by ${userInfo?.firstName} ${userInfo?.lastName}`;

				createAuditlogMutation.mutateAsync({
					appType: "WEB_BACK_OFFICE",
					module: "Beneficiary",
					action: "When re-enrolling a participant",
					detail: detail,
					actionCode: "WEB_PART_REENROLL",
					option: "Re-enroll",
					createdBy: userInfo?.id || "",
				});

				await onReenrollHandler(dataMapped);
			} else {
				createAuditlogMutation.mutateAsync({
					appType: "WEB_BACK_OFFICE",
					module: "Beneficiary",
					action: "When creating a new beneficiary",
					detail: `Created new beneficiary ${data.firstName} ${data.lastName}`,
					actionCode: "WEB_PART_PAR_ADD",
					option: "Add",
					createdBy: userInfo?.id || "",
				});

				await onCreateHandler(dataMapped);
			}
			onClose();
		} catch (error) {
			showError(
				(error as ErrorResponse).error?.errorMessage ||
					(error as ErrorResponse).message?.[0] ||
					"An error occurred",
			);
		}
	};

	useEffect(() => {
		if (programs) {
			if (programs.length === 1) {
				setIds((ids) => ({ ...ids, programId: programs[0]._id }));
				setValue("program", programs[0]._id as never);
				setSelectedProgram(programs[0]);
				setUserProgram(userInfo?.programs[0] as unknown as Program);
			}

			setProgramsOptions(
				programs.map((program) => ({
					key: program._id,
					value: program.name,
				})) || [],
			);
		}
	}, [programs]);

	useEffect(() => {
		if (providerSites) {
			if (providerSites.length === 1) {
				setIds((ids) => ({ ...ids, providerSiteId: providerSites[0].key }));
				setValue("providerSite", providerSites[0].key as never);
			}
		}
	}, [providerSites]);

	useEffect(() => {
		if (ids.providerSiteId) {
			refetchProviders();
		}
	}, [ids]);

	useEffect(() => {
		return () => {
			setIds({
				programId: "",
				providerId: "",
				providerSiteId: "",
			});

			setSelectedProgram(undefined);
			setUserProgram(undefined);
			queryClient.removeQueries({
				queryKey: ["sims-beneficiaries-register-provider-sites"],
			});
			queryClient.removeQueries({
				queryKey: ["sims-beneficiaries-providers"],
			});
		};
	}, [onClose]);

	useEffect(() => {
		if (beneficiary && programs) {
			setIds({
				programId: beneficiary.lastEnrollment.programId || "",
				providerSiteId: beneficiary.lastEnrollment.orgUnitId || "",
				providerId: beneficiary.lastEnrollment.providerId || "",
			});

			setSelectedProgram(
				programs?.find(
					(program) => program._id === beneficiary.lastEnrollment.programId,
				),
			);

			setUserProgram(
				userInfo?.programs?.find(
					(program) =>
						program.programId === beneficiary.lastEnrollment.programId,
				) as unknown as Program,
			);
		}
	}, [beneficiary, programs]);

	const onProgramChange = (selectedProgramId: string) => {
		queryClient.removeQueries({
			queryKey: ["sims-beneficiaries-register-provider-sites"],
		});
		queryClient.removeQueries({
			queryKey: ["sims-beneficiaries-providers"],
		});

		const values = getValues();

		reset({
			...values,
			program: selectedProgramId,
			providerSite: "",
			provider: "",
		});

		setIds({
			programId: selectedProgramId,
			providerId: "",
			providerSiteId: "",
		});

		setSelectedProgram(
			programs?.find((program) => program._id === selectedProgramId),
		);

		setUserProgram(
			userInfo?.programs?.find(
				(program) => program.programId === selectedProgramId,
			) as unknown as Program,
		);
	};

	useEffect(() => {
		refetchProviderSites();
	}, [userProgram]);

	const isDisabledProgram = React.useMemo(() => {
		if (isRegister) {
			if (programs?.length === 1) {
				return true;
			}
			return false;
		} else if (beneficiary?.lastEnrollment.isNewEnrollment) {
			if (programs?.length === 1) {
				return true;
			}

			return false;
		} else {
			return true;
		}
	}, [isRegister, programs, beneficiary]);

	const isDisabledProviderSite = React.useMemo(() => {
		if (isRegister) {
			if (isLoadingProviderSites) {
				return true;
			} else if (providerSites?.length === 1) {
				return true;
			} else {
				return false;
			}
		} else if (beneficiary?.lastEnrollment.isNewEnrollment) {
			if (providerSites?.length === 1) {
				return true;
			}

			return false;
		} else {
			return true;
		}
	}, [isRegister, beneficiary, isLoadingProviderSites]);

	const isDisableConsentForm = React.useMemo(() => {
		if (isRegister) {
			return false;
		} else if (beneficiary?.lastEnrollment.isNewEnrollment) {
			return false;
		} else {
			return true;
		}
	}, [isRegister, beneficiary]);

	let beneficiaryCINFormatSample = "";
	if (beneficiaryCINFormat?.default) {
		beneficiaryCINFormatSample = generateSampleFromRegex(
			beneficiaryCINFormat.default as string,
		);
	}

	return (
		<React.Fragment>
			<CustomDialog
				onClose={onClose}
				aria-labelledby="register-beneficiary-dialog-title"
				sx={{
					"& .MuiDialog-paper": {
						width: "100%",
						maxWidth: 760,
						minHeight: "calc(100vh - 64px)",
					},
				}}
				open={open}
			>
				<DialogTitle
					sx={{ m: 0, p: 2 }}
					id="register-beneficiary-dialog-title"
					variant="headlineSmall"
					color={(theme) => theme.palette.surface.light}
					bgcolor={(theme) => theme.palette.primary.dark}
				>
					{!isRegister ? "Re-enroll Beneficiary" : "Register Beneficiary"}
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.surface.light,
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent
					sx={{
						gap: "1rem",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box sx={{ width: "100%" }}>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs
								value={selectedTab}
								onChange={handleChange}
								aria-label="basic tabs example"
							>
								<Tab label="General information" {...a11yProps(0)} />
								<Tab label="SIMS INFORMATION" {...a11yProps(1)} />
							</Tabs>
						</Box>
						<TabPanel value={selectedTab} index={0}>
							{(displayBeneficiaryName?.default as boolean) && (
								<CustomStack direction="row">
									<Controller
										name="firstName"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<TextField
												{...field}
												id="firstName"
												label="First name"
												variant="outlined"
												required
												value={field.value}
												onChange={(value) => field.onChange(value)}
												error={!!errors.firstName}
												helperText={
													errors.firstName ? errors.firstName.message : null
												}
											/>
										)}
									/>
									<Controller
										name="lastName"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<TextField
												{...field}
												id="lastName"
												label="Last name"
												variant="outlined"
												required
												value={field.value}
												onChange={(value) => field.onChange(value)}
												error={!!errors.lastName}
												helperText={
													errors.lastName ? errors.lastName.message : null
												}
											/>
										)}
									/>
								</CustomStack>
							)}
							<CustomStack direction="row">
								<Controller
									name="email"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											{...field}
											id="email"
											label="Email"
											variant="outlined"
											value={field.value}
											onChange={(value) => field.onChange(value)}
											error={!!errors.email}
											helperText={errors.email ? errors.email.message : null}
										/>
									)}
								/>
								<Controller
									name="phoneNumber"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<MuiTelInput
											{...field}
											defaultCountry="US"
											disableDropdown
											label="Phone number"
											variant="outlined"
											className="flex flex-1"
											value={field.value}
											onChange={(value) => field.onChange(value)}
											error={!!errors.phoneNumber}
											helperText={
												errors.phoneNumber ? errors.phoneNumber.message : null
											}
										/>
									)}
								/>
							</CustomStack>
							<CustomStack direction="row">
								<Controller
									name="dateOfBirth"
									control={control}
									render={({ field }) => (
										<div>
											<BasicDatepicker
												{...field}
												label="Date of birth (mm/dd/yyyy)*"
												onChange={(value) => field.onChange(value)}
												value={field.value ? dayjs(field.value) : null}
											/>
											{errors.dateOfBirth && (
												<FormHelperText className="!ml-[14px]" error>
													{errors.dateOfBirth.message}
												</FormHelperText>
											)}
										</div>
									)}
								/>
							</CustomStack>
							{(displayBeneficiaryAddress?.default as boolean) && (
								<>
									<Stack spacing={{ xs: 1, sm: 2 }}>
										<Controller
											name="addressLine1"
											control={control}
											defaultValue=""
											render={({ field }) => (
												<TextField
													{...field}
													id="addressLine1"
													label="Address line 1"
													variant="outlined"
													className="flex flex-1"
													value={field.value}
													onChange={(value) => field.onChange(value)}
													error={!!errors.addressLine1}
													helperText={
														errors.addressLine1
															? errors.addressLine1.message
															: null
													}
												/>
											)}
										/>
										<Controller
											name="addressLine2"
											control={control}
											defaultValue=""
											render={({ field }) => (
												<TextField
													{...field}
													id="addressLine2"
													label="Address line 2"
													variant="outlined"
													className="flex flex-1"
													value={field.value}
													onChange={(value) => field.onChange(value)}
													error={!!errors.addressLine2}
													helperText={
														errors.addressLine2
															? errors.addressLine2.message
															: null
													}
												/>
											)}
										/>
									</Stack>
									<CustomStack direction="row">
										<Controller
											name="city"
											control={control}
											defaultValue=""
											render={({ field }) => (
												<TextField
													{...field}
													id="city"
													label="City"
													variant="outlined"
													className="flex flex-1"
													value={field.value}
													onChange={(value) => field.onChange(value)}
													error={!!errors.city}
													helperText={errors.city ? errors.city.message : null}
												/>
											)}
										/>
										<Controller
											name="state"
											control={control}
											defaultValue=""
											render={({ field }) => (
												<TextField
													{...field}
													id="state"
													label="State"
													variant="outlined"
													className="flex flex-1"
													value={field.value}
													onChange={(value) => field.onChange(value)}
													error={!!errors.state}
													helperText={
														errors.state ? errors.state.message : null
													}
												/>
											)}
										/>
									</CustomStack>
									<CustomStack direction="row">
										<Controller
											name="zipCode"
											control={control}
											defaultValue=""
											render={({ field }) => (
												<TextField
													{...field}
													id="zipCode"
													label="ZIP Code"
													variant="outlined"
													className="flex flex-1"
													value={field.value}
													onChange={(value) => field.onChange(value)}
													error={!!errors.zipCode}
													helperText={
														errors.zipCode ? errors.zipCode.message : null
													}
												/>
											)}
										/>
									</CustomStack>
								</>
							)}
						</TabPanel>
						<TabPanel value={selectedTab} index={1}>
							<CustomStack direction="row">
								<Controller
									name="program"
									control={control}
									defaultValue={ids.programId ?? undefined}
									render={({ field }) => (
										<BasicSelect
											{...field}
											data={programsOptions || []}
											value={field.value}
											required
											label="Program"
											id="beneficiary-program"
											handleChange={(value) => {
												const selectedProgramId = value as string;
												field.onChange(selectedProgramId);

												onProgramChange(selectedProgramId);
											}}
											error={!!errors.program}
											errorMessage={errors.program?.message || ""}
											isLoading={isLoadingPrograms || isDisabledProgram}
										/>
									)}
								/>
								<Controller
									name="providerSite"
									control={control}
									defaultValue={
										providerSites?.length === 1
											? providerSites[0].key
											: ids.providerSiteId
									}
									render={({ field }) => (
										<BasicSelect
											{...field}
											data={providerSites || []}
											value={field.value}
											required
											label="Provider site"
											id="beneficiary-provider-site"
											handleChange={(value) => {
												field.onChange(value);
												setIds((ids) => ({
													...ids,
													providerSiteId: value as string,
												}));
											}}
											error={!!errors.providerSite}
											errorMessage={errors.providerSite?.message || ""}
											isLoading={ids.programId === "" || isDisabledProviderSite}
										/>
									)}
								/>
							</CustomStack>
							<CustomStack direction="row">
								<Controller
									name="provider"
									control={control}
									defaultValue={
										providers?.length === 1 ? providers[0].key : ids.providerId
									}
									render={({ field }) => (
										<BasicSelect
											{...field}
											data={providers || []}
											value={field.value}
											required
											label="Provider"
											id="beneficiary-provider"
											handleChange={(value) => field.onChange(value)}
											error={!!errors.provider}
											errorMessage={errors.provider?.message || ""}
											isLoading={
												ids.providerSiteId === "" || isLoadingProviders
											}
										/>
									)}
								/>
								<Controller
									name="clientIDNumber"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											{...field}
											id="state"
											label="Client ID Number (CIN)"
											required
											variant="outlined"
											className="flex flex-1"
											value={field.value}
											onChange={(value) => field.onChange(value)}
											error={!!errors.clientIDNumber}
											placeholder={beneficiaryCINFormatSample ?? undefined}
											helperText={
												errors.clientIDNumber
													? errors.clientIDNumber.message
													: null
											}
										/>
									)}
								/>
							</CustomStack>
							<Grid
								container
								rowSpacing={2}
								columnSpacing={1}
								style={{ marginTop: "0px" }}
							>
								<Controller
									name="cmVisitSchedule"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<Grid item xs={6}>
											<BasicSelect
												{...field}
												data={[
													{
														key: "MondayThursday",
														value: "Monday and Thursday",
													},
													{ key: "TuesdayFriday", value: "Tuesday and Friday" },
												]}
												value={field.value}
												required
												label="CM visit schedule"
												id="beneficiary-program"
												handleChange={(value) => field.onChange(value)}
												error={!!errors.cmVisitSchedule}
												errorMessage={errors.cmVisitSchedule?.message ?? ""}
											/>
										</Grid>
									)}
								/>
								{selectedProgram?.parameters.eligibility && (
									<Controller
										name="medicaidEligibility"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<Grid item xs={6}>
												<BasicSelect
													{...field}
													data={[
														{ key: "true", value: "Yes" },
														{ key: "false", value: "No" },
													]}
													value={field.value}
													required
													label="Medicaid eligibility"
													id="beneficiary-medicaid-eligibility"
													handleChange={(value) => field.onChange(value)}
												/>
											</Grid>
										)}
									/>
								)}
								<>
									{displayBeneficiaryCMEligible?.default && (
										<Controller
											name="cmEligibility"
											control={control}
											defaultValue={false}
											render={({ field }) => (
												<Grid item xs={6} className="mt-3">
													<BasicSelect
														{...field}
														data={[
															{ key: "true", value: "Yes" },
															{ key: "false", value: "No" },
														]}
														value={field.value ? "true" : "false"}
														required
														label="CM Eligible"
														id="beneficiary-cm-eligibility"
														handleChange={(value) =>
															field.onChange(value === "true" ? true : false)
														}
														error={!!errors.cmEligibility}
														errorMessage={errors.cmEligibility?.message ?? ""}
													/>
												</Grid>
											)}
										/>
									)}
								</>
							</Grid>

							<Box sx={{ py: 2 }}>
								<Typography
									variant="titleSmall"
									color={theme.palette.accent.main}
								>
									Consent form
								</Typography>

								<Box sx={{ py: 2 }}>
									<Controller
										name="file"
										control={control}
										defaultValue={undefined}
										render={({ field }) => (
											<>
												<UploadButton
													onChange={(value) => field.onChange(value)}
													value={field.value as File}
													disabled={
														createBeneficiary.isPending ||
														reenrollBeneficiary.isPending ||
														isDisableConsentForm
													}
												/>
												{errors.file && (
													<FormHelperText className="!ml-[14px]" error>
														{errors.file?.message}
													</FormHelperText>
												)}
											</>
										)}
									/>
								</Box>
							</Box>
						</TabPanel>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button size="large" onClick={onClose} variant="outlined">
						CANCEL
					</Button>
					<Button
						size="large"
						onClick={async () => {
							await trigger();

							const totalAmountEarnedByCurrentYear =
								beneficiary?.totalAmountEarnedByCurrentYear || 0;
							const maxYearlyEarnings = beneficiary?.lastEnrollment?.program
								?.parameters?.maxYearlyEarnings as number;

							if (
								!isRegister &&
								totalAmountEarnedByCurrentYear >= maxYearlyEarnings
							) {
								setOpenGenericDialog(true);
								return;
							}

							handleSubmit(onSubmit)();
						}}
						color="primary"
						variant="contained"
						sx={{ textTransform: "none", textAlign: "center" }}
						disabled={
							createBeneficiary.isPending || reenrollBeneficiary.isPending
						}
					>
						{!isRegister ? "REENROLL" : "REGISTER"}
					</Button>
				</DialogActions>
			</CustomDialog>

			{openGenericDialog ? (
				<GenericAlertModal
					title="Error"
					description={`Beneficiary earned the maximum amount of Incentives this year and is no longer eligible to receive CM Services or re-enroll until the 1st of January of ${dayjs().add(1, "year").year()}.`}
					submitText="Accept"
					onClick={() => setOpenGenericDialog(false)}
				/>
			) : null}
		</React.Fragment>
	);
}
