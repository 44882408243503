import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Button, CircularProgress, DialogActions } from "@mui/material";
import { useGetOrganizationList } from "../../hooks/useOrganizations";
import { useFeedBack } from "../../providers/FeedBackProvider/FeedBackContext";

const PreviewAssignedOuDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

interface IdName {
	id: string;
	name: string;
}

interface IOrganization extends IdName {
	parentOrgUnitId: string | undefined;
}

interface ProgramOrganizationGroup {
	programId: string;
	organizationIdsList: string[];
}

interface PreviewAssignedOuModalProps {
	open: boolean;
	onClose: () => void;
	programOrganizationGroups: ProgramOrganizationGroup[];
	allPrograms: IdName[];
	confirmAction?: () => void;
}

interface FullOrganizationGroup {
	id: string;
	name: string;
	organizationsGroups: {
		parentName: string;
		parentId: string | undefined;
		organizationsAssociated: IdName[];
	}[];
}

function fillNames(
	allOrganizations: IOrganization[],
	allPrograms: IdName[],
	programOrganizationGroup: ProgramOrganizationGroup,
): FullOrganizationGroup {
	const program = allPrograms.find(
		(program) => program.id === programOrganizationGroup.programId,
	);
	const programName = program ? program.name : "Unknown Program";

	// group organizations by parent
	const parentOrganizationsGroups =
		programOrganizationGroup.organizationIdsList.reduce(
			(acc, orgId) => {
				const orgPivot = allOrganizations.find((org) => org.id === orgId);
				if (!orgPivot) return acc;

				const parentOrg = allOrganizations.find(
					(org) => org.id === orgPivot.parentOrgUnitId,
				);
				// is level 0
				if (!parentOrg) {
					acc.push({
						parentName: orgPivot.name,
						parentId: orgPivot.id,
						organizationsAssociated: [],
					});

					return acc;
				}

				const parentOrgGroup = acc.find(
					(group) => group.parentId === parentOrg.id,
				);
				if (parentOrgGroup) {
					parentOrgGroup.organizationsAssociated.push(orgPivot);
				} else {
					acc.push({
						parentName: parentOrg.name,
						parentId: parentOrg.id,
						organizationsAssociated: [orgPivot],
					});
				}

				return acc;
			},
			[] as FullOrganizationGroup["organizationsGroups"],
		);

	return {
		id: programOrganizationGroup.programId,
		name: programName,
		organizationsGroups: parentOrganizationsGroups,
	};
}

function makeTheTree(programOrgGroup: FullOrganizationGroup) {
	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ display: "flex", flexDirection: "column", px: 1 }}>
				<Box sx={{ display: "flex", py: 1 }}>
					<Typography
						color="textSecondary.main"
						fontWeight="bold"
						paddingRight={1}
					>
						PROGRAM:
					</Typography>
					<Typography color="textSecondary.main">
						{programOrgGroup.name}
					</Typography>
				</Box>
				<Typography color="textSecondary.main" fontWeight="bold">
					OU SELECTED:
				</Typography>
			</Box>
			<Box sx={{ py: 2, px: 1 }} className="space-y-4">
				<Card
					sx={{
						width: "70%",
						display: "flex",
						flexDirection: "column",
						flexGrow: 1,
						padding: "1rem 2rem",
						alignItems: "flex-start",
					}}
				>
					<SimpleTreeView
						defaultExpandedItems={programOrgGroup.organizationsGroups.map(
							(o) => o.parentId || "",
						)}
					>
						{programOrgGroup.organizationsGroups.map((group) => (
							<TreeItem
								itemId={group.parentId || ""}
								label={group.parentName}
								sx={{ py: 2 }}
							>
								{group.organizationsAssociated.map((childOrganization) => (
									<TreeItem
										itemId={childOrganization.id}
										label={childOrganization.name}
										sx={{ py: 2 }}
									/>
								))}
							</TreeItem>
						))}
					</SimpleTreeView>
				</Card>
			</Box>
		</Box>
	);
}

const PreviewAssignedOuModal: FC<PreviewAssignedOuModalProps> = ({
	open,
	onClose,
	programOrganizationGroups,
	confirmAction,
	allPrograms,
}: PreviewAssignedOuModalProps) => {
	const { data: allOrganizationGroups, isLoading } = useGetOrganizationList(
		process.env.REACT_APP_ACCOUNT as string,
	);

	const [confirmClicked, setConfirmClicked] = React.useState(false);
	const { showSnackBar } = useFeedBack();
	return (
		<React.Fragment>
			<PreviewAssignedOuDialog
				onClose={onClose}
				aria-labelledby="register-beneficiary-dialog-title"
				sx={{
					"& .MuiDialog-paper": {
						width: "100%",
						maxWidth: 800,
						minHeight: 400,
					},
				}}
				open={open}
			>
				<DialogTitle
					sx={{ m: 0, p: 2 }}
					id="register-beneficiary-dialog-title"
					variant="headlineSmall"
					color={(theme) => theme.palette.surface.light}
					bgcolor={(theme) => theme.palette.primary.dark}
				>
					Preview assigned OU
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.surface.light,
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent
					sx={{
						gap: "1rem",
						display: "flex",
						flexDirection: "column",
					}}
				>
					{isLoading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							height="240px"
						>
							<CircularProgress />
						</Box>
					) : (
						programOrganizationGroups.map((organizationGroup) => {
							return makeTheTree(
								fillNames(
									allOrganizationGroups || [],
									allPrograms,
									organizationGroup,
								),
							);
						})
					)}
				</DialogContent>
				{confirmAction && (
					<DialogActions>
						<Button
							onClick={() => {
								if (programOrganizationGroups.length === 0) {
									showSnackBar(
										"Please select at least one organizational unit",
										"error",
									);
									onClose();
									return;
								}
								setConfirmClicked(true);
								confirmAction();
							}}
							variant="contained"
							color="primary"
							disabled={confirmClicked}
						>
							Confirm
						</Button>
					</DialogActions>
				)}
			</PreviewAssignedOuDialog>
		</React.Fragment>
	);
};

export default PreviewAssignedOuModal;
