import { FC, ReactNode, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DataTable, { HeadCell, DataTableCell } from "../../components/DataTable";
import Button from "@mui/material/Button";
import { useAppTheme } from "../../utils/theme";
import dayjs from "dayjs";
import { useGetCards } from "../../hooks/useCards";
import ActionMenu from "../../components/ActionMenu";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import AuditLogModal from "../../components/modals/AuditLog";
import {
	OrderModalProvider,
	useOrderModal,
} from "../../components/modals/AddOrder/OrderModalContext";
import {
	useViewOrderModal,
	ViewOrderModalProvider,
} from "../../components/modals/ViewOrder/ViewOrderModalContext";
import ViewOrderModal from "../../components/modals/ViewOrder";
import AddOrderModal from "../../components/modals/AddOrder";
import { useTranslation } from "react-i18next";
import TreeView from "../../components/TreeView/Index";
import { useUserStore } from "../../stores/user.store";
import { useGetProviderSitesByOUId } from "../../hooks/useBeneficiaries";

const CardListComponent: FC = () => {
	const { t } = useTranslation("cards");
	const theme = useAppTheme();
	const { openModal, isOpen } = useOrderModal();
	const { openModal: openViewOrderModal } = useViewOrderModal();
	const [openAuditLogModal, setOpenAuditLogModal] = useState(false);
	const { userInfo } = useUserStore();

	const userOrganizations = userInfo?.programs.flatMap((p) =>
		p.orgUnitId.map((ou) => (typeof ou === "string" ? ou : ou.orgUnitId)),
	);

	const [filters, setFilters] = useState({
		providerSiteId: "",
		orderId: "",
		pageNumber: 0,
		pageSize: 10,
	});

	const {
		data: cardsData,
		isLoading,
		refetch: refetchCardOrders,
	} = useGetCards(
		"",
		filters?.providerSiteId || "",
		filters.pageSize,
		filters.pageNumber + 1,
	);

	useEffect(() => {
		if (isOpen === false) {
			refetchCardOrders();
		}
	}, [isOpen, refetchCardOrders]);

	const headCellsConfig: HeadCell[] = [
		{
			id: "orderId",
			disablePadding: true,
			label: t("order_id"),
			isSortable: true,
		},
		{
			id: "dateTime",
			disablePadding: false,
			label: t("date_and_time"),
			isSortable: true,
		},
		{
			id: "ou",
			disablePadding: false,
			label: t("organization_unit"),
			isSortable: true,
		},
		{
			id: "numberOfCards",
			disablePadding: false,
			label: t("number_of_cards"),
			isSortable: true,
		},
		{
			id: "action",
			disablePadding: false,
			label: t("action"),
			isSortable: false,
		},
	];

	const createData = (
		id: number,
		orderId: ReactNode,
		dateTime: ReactNode,
		ou: ReactNode,
		numberOfCards: ReactNode,
		action: ReactNode,
	): DataTableCell => {
		return {
			id,
			orderId,
			dateTime,
			ou,
			numberOfCards,
			action,
		};
	};

	const rows =
		cardsData?.data.map((item, index) => {
			const actionMenu = [
				{
					value: t("view"),
					onClick: () => openViewOrderModal(item.id), // Pass order.id here
				},
			];

			return createData(
				index,
				<div>{item.id}</div>,
				<div>{dayjs(item.orderDate).format("MM/DD/YYYY HH:mm")}</div>,
				<div>{item.organizationName}</div>,
				<div>{item.cards.length}</div>,
				<ActionMenu id={item.id} options={actionMenu} />,
			);
		}) || [];

	const { data: providerSites, refetch: refetchProviderSites } =
		useGetProviderSitesByOUId({
			ouIds: userOrganizations || [],
			createTree: true,
			queryKey: "sims-beneficiaries-list-provider-sites",
		});

	useEffect(() => {
		if (filters.providerSiteId) {
			refetchCardOrders();
		}
	}, [filters, refetchCardOrders]);

	useEffect(() => {
		refetchProviderSites();
	}, []);

	const headCells: HeadCell[] = headCellsConfig;
	return (
		<>
			<div className="w-full h-full overflow-y-auto scroll-smooth">
				<div className="container">
					<div className="flex flex-col items-center justify-start min-h-screen space-y-4">
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								width: "100%",
							}}
						>
							<Typography variant="headlineSmall">{t("card_list")}</Typography>
						</Box>

						<Card sx={{ minWidth: 275, width: "100%" }}>
							<CardContent className="space-y-5">
								<Box
									sx={{
										display: "flex",
										gap: "1rem",
									}}
								>
									<TreeView
										key={filters.providerSiteId}
										label="Filter by OU"
										selectedId={filters.providerSiteId}
										expanded
										onSelect={(id) => {
											setFilters((filters) => ({
												...filters,
												providerSiteId: id,
												pageNumber: 0,
											}));
										}}
										data={providerSites || []}
										anchorEl={null}
										includeChildren
									/>

									<Tooltip title={t("audit_log")}>
										<IconButton
											size="large"
											sx={{
												borderRadius: "8px",
												border: `1px solid ${theme.palette.outline.dark}`,
												width: "3.5rem",
											}}
											onClick={() => setOpenAuditLogModal(true)}
										>
											<HistoryOutlinedIcon className="text-icon-primary" />
										</IconButton>
									</Tooltip>

									<Button
										size="large"
										onClick={openModal}
										color="primary"
										variant="contained"
										sx={{ textTransform: "none", textAlign: "center" }}
									>
										{t("add_order")}
									</Button>
								</Box>

								<DataTable
									data={rows}
									rowsPerPage={filters.pageSize}
									order="asc"
									orderBy="none"
									page={filters.pageNumber}
									headCells={headCells}
									total={cardsData?.total}
									isLoading={isLoading}
									onChangePage={(event, newPage) => {
										setFilters((filters) => ({
											...filters,
											pageNumber: newPage,
										}));
									}}
									onChangeSize={(event) => {
										setFilters((filters) => ({
											...filters,
											pageNumber: 0,
											pageSize: Number(event.target.value),
										}));
									}}
								/>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
			{openAuditLogModal ? (
				<AuditLogModal
					open={openAuditLogModal}
					module="Phyiscal Prepaidcard"
					onClose={() => setOpenAuditLogModal(false)}
				/>
			) : null}
		</>
	);
};

const CardList: FC = () => (
	<OrderModalProvider>
		<ViewOrderModalProvider>
			<CardListComponent />
			<AddOrderModal />
			<ViewOrderModal />
		</ViewOrderModalProvider>
	</OrderModalProvider>
);

export default CardList;
