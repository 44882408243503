import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { IAlertEvent } from "@/domain/alertEvent.interface";
import { EVENT_ENUM } from "../../domain/events.enum";
import { useSettings } from "../../hooks/useSetting";
import { BeneficiaryItem } from "@/domain/beneficiary.interface";
import { useAppTheme } from "../../utils/theme";

export const createLinkedNarrative = (
	alertEvent: IAlertEvent,
): React.ReactNode => {
	const theme = useAppTheme();
	const { displayBeneficiaryName } = useSettings();
	const {
		actionCode,
		beneficiaryId,
		fullName,
		program,
		narrative,
		beneficiary,
	} = alertEvent;
	const narrativeName =
		`${beneficiary?.firstName} ${beneficiary?.lastName}`.trim();
	const narrativeCIN = `${beneficiary?.sims?.clientIdNumber}`.trim();
	const beneficiaryIdString =
		typeof beneficiaryId === "string"
			? beneficiaryId
			: (beneficiaryId as BeneficiaryItem)?._id;
	const linkedName = (
		<Link
			component={RouterLink}
			to={`/beneficiaries/${beneficiaryIdString}`}
			underline="hover"
			style={{
				textDecoration: "underline",
				color: theme.palette.textMain.dark4,
			}}
		>
			{displayBeneficiaryName?.default
				? narrativeName || fullName
				: narrativeCIN}
		</Link>
	);

	let backendNarrative = narrative;
	if (narrativeCIN && narrative.includes(narrativeCIN)) {
		backendNarrative = narrative.split(narrativeCIN)[1];
	} else if (narrativeName && narrative.includes(narrativeName)) {
		backendNarrative = narrative.split(narrativeName)[1];
	}

	switch (actionCode) {
		case EVENT_ENUM.EVE_BEN_ENROLL:
			return (
				<>
					Beneficiary {linkedName} has been enrolled to the {program?.name}{" "}
					program!
				</>
			);

		case EVENT_ENUM.EVE_BEN_COMPL:
			return (
				<>
					Beneficiary {linkedName} has completed the {program?.name} program!
				</>
			);

		case EVENT_ENUM.EVE_BEN_DISENROLL:
			return (
				<>
					Beneficiary {linkedName} has been disenrolled from the {program?.name}{" "}
					program!
				</>
			);

		case EVENT_ENUM.EVE_MOVE_TO:
			return (
				<>
					Beneficiary {linkedName}
					{backendNarrative}
				</>
			);

		case EVENT_ENUM.EVE_MOVE_FROM:
			return <>{narrative}</>;

		case EVENT_ENUM.EVE_USER_ENROLL:
			return <>{narrative}</>;

		case EVENT_ENUM.EVE_PROV_ACCOUNT:
			return <>{narrative}</>;

		case EVENT_ENUM.EVE_MISSED_ENTRY:
			return <>Beneficiary {linkedName} has missed entries.</>;

		case EVENT_ENUM.EVE_CM_ELIG:
			return (
				<>Beneficiary {linkedName} requires a CM eligibility reconfirmation.</>
			);

		case EVENT_ENUM.EVE_31DAYS_ABS:
			return (
				<>
					Beneficiary {linkedName} should be disenrolled from the{" "}
					{program?.name} program due to 31 days of absence.
				</>
			);

		case EVENT_ENUM.EVE_UDT_NOTES_UPD:
		case EVENT_ENUM.EVE_UDT_RESULT_UPD:
			return (
				<>
					{linkedName}
					{backendNarrative}
				</>
			);

		default:
			return alertEvent.narrative;
	}
};
