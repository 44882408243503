import { FC, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import BasicSelect from "../../components/BasicSelect";
import TreeView from "../../components/TreeView/Index";
import { Program } from "../../domain/beneficiary.interface";
import { useUserStore } from "../../stores/user.store";
import {
	useGetProgramsByIds,
	useGetProviderSitesByOUId,
	useGetProvidersByProgramId,
} from "../../hooks/useBeneficiaries";
import { useQueryClient } from "@tanstack/react-query";
import { useSettings } from "../../hooks/useSetting";

export type ReportFiltersProps = {
	programId: string[];
	providerSiteId: string[];
	providerId: string;
	providerNpi: string;
	beneficiary: string;
	beneficiaryCin: string;
};

type Props = {
	filters: ReportFiltersProps;
	onFiltersChange: (newFilters: ReportFiltersProps) => void;
	showBeneficiaryFilters?: boolean;
};

export const ReportFilters: FC<Props> = ({
	filters,
	onFiltersChange,
	showBeneficiaryFilters = true,
}) => {
	const { userInfo } = useUserStore();
	const queryClient = useQueryClient();
	const { displayBeneficiaryName } = useSettings();

	const userPrograms =
		userInfo?.programs.map((program) => program.programId) || [];

	const [programsOptions, setProgramsOptions] = useState<
		Array<{ key: string; value: string }>
	>([]);
	const [providerOptions, setProviderOptions] = useState<
		Array<{ key: string; value: string }>
	>([]);

	const [programOuiSelected, setProgramOuiSelected] = useState<string[]>();

	// programs
	const { data: programsData, isLoading: isLoadingPrograms } =
		useGetProgramsByIds(userPrograms);

	// provider sites
	const { data: providerSitesData, refetch: refetchProviderSites } =
		useGetProviderSitesByOUId({
			ouIds: programOuiSelected || [],
			createTree: true,
			queryKey: "sims-beneficiaries-list-provider-sites",
		});

	// providers
	const { isLoading: isLoadingProviders, refetch: refetchProviders } =
		useGetProvidersByProgramId(
			filters.programId,
			filters.providerSiteId,
			false,
		);

	const onProgramFilterChange = (value: string | string[]) => {
		if (!Array.isArray(value)) {
			const valueArray = value.includes(",") ? value.split(",") : [value];
			const newFilters = {
				...filters,
				programId: valueArray,
				providerId: "",
				providerSiteId: [],
			};
			onFiltersChange(newFilters);

			const userProgramsOuis =
				valueArray.length > 1
					? Array.from(
							new Set(
								(
									userInfo?.programs.map(
										(program) => program.orgUnitId,
									) as unknown as string[]
								).flat(),
							),
						)
					: Array.from(
							new Set([
								(
									userInfo?.programs?.find(
										(program) => program.programId === valueArray[0],
									) as unknown as Program
								)?.orgUnitId,
							]),
						);

			setProgramOuiSelected(userProgramsOuis as string[]);
			setProviderOptions([]);
		}
	};

	useEffect(() => {
		if (programsData && !isLoadingPrograms) {
			const programsKeys = programsData
				.map((program) => program._id)
				.sort()
				.join(",");
			const allProgramsOption = { key: programsKeys, value: "All program" };
			const programOptions = programsData.map((program) => ({
				key: program._id,
				value: program.name,
			}));

			const hasAllPrograms = programsData.length > 1;

			const options = hasAllPrograms
				? [allProgramsOption, ...programOptions]
				: programOptions;
			setProgramsOptions(options);

			const userProgramsOuis = hasAllPrograms
				? Array.from(
						new Set(
							(
								userInfo?.programs.map(
									(program) => program.orgUnitId,
								) as unknown as string[]
							).flat(),
						),
					)
				: Array.from(
						new Set([
							(
								userInfo?.programs?.find(
									(program) => program.programId === programsKeys,
								) as unknown as Program
							)?.orgUnitId,
						]),
					);

			setProgramOuiSelected(userProgramsOuis as string[]);

			const newFilters = {
				...filters,
				programId: [programsKeys],
				providerId: "",
				providerSiteId: [],
			};
			onFiltersChange(newFilters);
			setProviderOptions([]);
		}
	}, [programsData, isLoadingPrograms]);

	useEffect(() => {
		if (programOuiSelected) {
			refetchProviderSites();
		}
	}, [programOuiSelected, refetchProviderSites]);

	useEffect(() => {
		if (
			filters.programId.length > 0 &&
			filters.providerSiteId.length > 0 &&
			!isLoadingProviders
		) {
			refetchProviders().then((data) => {
				setProviderOptions(data?.data || []);
			});
		}
	}, [isLoadingProviders, filters]);

	return (
		<>
			<BasicSelect
				handleChange={onProgramFilterChange}
				data={programsOptions || []}
				value={filters.programId}
				label="Select Program"
				id="report-program"
				placeholder="Select"
				isLoading={isLoadingPrograms}
			/>

			<TreeView
				key={filters.providerSiteId.join(",")}
				label="Select OU"
				textFieldProps={{ placeholder: "Select" }}
				selectedId={filters.providerSiteId[0]}
				expanded
				onSelect={(id) => {
					queryClient.removeQueries({
						queryKey: ["sims-beneficiaries-providers"],
					});

					const newFilters = {
						...filters,
						providerSiteId: id.split(","),
						providerId: "",
					};
					onFiltersChange(newFilters);
					setProviderOptions([]);
				}}
				data={providerSitesData || []}
				anchorEl={null}
				includeChildren
			/>

			<BasicSelect
				handleChange={(value) => {
					const newFilters = {
						...filters,
						providerId: value as string,
					};
					onFiltersChange(newFilters);
				}}
				data={providerOptions}
				value={filters.providerId}
				isLoading={
					filters.programId.includes("all") ||
					providerOptions.length === 0 ||
					isLoadingProviders
				}
				label="Select Provider"
				id="report-provider"
				placeholder="Select"
			/>
			<TextField
				label="Provider NPI"
				value={filters.providerNpi}
				onChange={(e) =>
					onFiltersChange({
						...filters,
						providerNpi: e.target.value,
					})
				}
			/>
			{showBeneficiaryFilters && (
				<>
					{displayBeneficiaryName?.default ? (
						<TextField
							label="Beneficiary"
							value={filters.beneficiary}
							onChange={(e) =>
								onFiltersChange({
									...filters,
									beneficiary: e.target.value,
								})
							}
						/>
					) : (
						<></>
					)}
					<TextField
						label="Beneficiary CIN"
						value={filters.beneficiaryCin}
						onChange={(e) =>
							onFiltersChange({
								...filters,
								beneficiaryCin: e.target.value,
							})
						}
					/>
				</>
			)}
		</>
	);
};

export default ReportFilters;
