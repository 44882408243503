import React from "react";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from "@mui/material";
import { useAppTheme } from "../../../utils/theme";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

export interface GenericModalProps {
	onClick?: () => void;
	onCancel?: () => void;
	title: string;
	description: string;
	submitText: string;
	cancelText?: string;
	isLoading?: boolean;
	icon?: React.ReactNode;
}

const GenericAlertModal: React.FC<GenericModalProps> = ({
	onClick,
	onCancel,
	title,
	description,
	submitText,
	cancelText,
	isLoading,
	icon,
}) => {
	const theme = useAppTheme();

	return (
		<Dialog
			open={true}
			onClose={onCancel}
			PaperProps={{
				style: {
					width: "100%",
					maxWidth: "280px",
					display: "flex",
					backgroundColor: theme.palette.surface.dark,
					textAlign: "center",
					borderRadius: "15px",
				},
			}}
		>
			{isLoading ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="240px"
					padding="24px"
				>
					<CircularProgress />
				</Box>
			) : (
				<>
					<div style={{ marginTop: "1.5rem" }}>
						{icon || <CancelPresentationIcon fontSize="medium" />}
					</div>
					<DialogTitle sx={{ padding: "1rem 1.25rem" }}>
						<Typography variant="headlineSmall">{title}</Typography>
					</DialogTitle>
					<DialogContent style={{ textAlign: "left" }}>
						<DialogContentText variant="bodyMedium" color="black">
							{description}
						</DialogContentText>
					</DialogContent>
					<DialogActions
						style={{ paddingRight: "24px", paddingBottom: "24px" }}
					>
						{cancelText && (
							<Button
								onClick={onCancel}
								color="primary"
								variant="text"
								style={{ textTransform: "capitalize", fontWeight: "bold" }}
							>
								{cancelText}
							</Button>
						)}
						<Button
							onClick={onClick}
							color="primary"
							variant="text"
							style={{ textTransform: "capitalize", fontWeight: "bold" }}
						>
							{submitText}
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};
export default GenericAlertModal;
