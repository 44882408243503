import { FC, useState, useEffect, useCallback } from "react";
import { Autocomplete, TextField, CircularProgress, Box } from "@mui/material";
import { useGetSearchBeneficiary } from "../../hooks/useBeneficiaries";
import useDebounce from "../../hooks/useDebounce";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useAppTheme } from "../../utils/theme";
import { BeneficiaryItemSearched } from "@/domain/beneficiary.interface";

type Props = {
	children?: React.ReactNode;
};

const BeneficiarySearchBar: FC<Props> = () => {
	const [searchedVal, setSearchedVal] = useState("");
	const navigate = useNavigate();
	const theme = useAppTheme();
	const [options, setOptions] = useState<BeneficiaryItemSearched[]>([]);
	const debouncedInputValue = useDebounce({ value: searchedVal, delay: 100 });

	const {
		data: dataBeneficiariesSearched,
		refetch: refetchSearchBeneficiary,
		isFetching,
	} = useGetSearchBeneficiary(debouncedInputValue);

	const memoizedRefetch = useCallback(refetchSearchBeneficiary, [
		refetchSearchBeneficiary,
	]);

	useEffect(() => {
		memoizedRefetch();
	}, [debouncedInputValue, memoizedRefetch]);

	useEffect(() => {
		if (dataBeneficiariesSearched) {
			setOptions(dataBeneficiariesSearched);
		}
	}, [dataBeneficiariesSearched]);

	return (
		<Autocomplete
			sx={{
				minWidth: 180,
				display: "flex",
				flexDirection: "column",
				flexGrow: 1,
				maxWidth: "100%",
				ml: "-1rem",
			}}
			id="autocomplete-beneficiary-search"
			getOptionLabel={(option) => option.fullname}
			options={options}
			noOptionsText={
				isFetching ? (
					<Box display="flex" justifyContent="center" alignItems="center">
						<CircularProgress sx={{ color: theme.palette.primary.main }} />
					</Box>
				) : (
					"No data found"
				)
			}
			disableClearable
			onInputChange={(event, newInputValue) => {
				setSearchedVal(newInputValue);
			}}
			onChange={(event, newValue) => {
				navigate(`/beneficiaries/${newValue?._id}`);
			}}
			renderInput={(params) => (
				<TextField
					sx={{
						backgroundColor: theme.palette.surface.main,
						borderRadius: ".5rem",
						"& fieldset": { border: "none" },
					}}
					focused={false}
					{...params}
					placeholder="Search..."
					InputProps={{
						...params.InputProps,
						type: "search",
						endAdornment: (
							<SearchIcon
								sx={{ mr: "-1.5rem" }}
								className="text-icon-primary"
							/>
						),
					}}
				/>
			)}
		/>
	);
};

export default BeneficiarySearchBar;
